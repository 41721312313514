import { securedAxiosAPI } from 'utils/axios';
import { store } from '../../store';

const securedRoute = {
    addNewGaiapod: 'gaiapod-device/add',
    list: 'gaiapod-device/paging?pageSize=##PAGE_SIZE##&pageIndex=##PAGE_INDEX##',
    updateGaiapod: 'gaiapod-device/update/##ID##',
    getGaiapodDetailsById: 'gaiapod-device/##ID##',
    getGaiapodHistory: '/gaiapod-device/history/##ID##?pageSize=##PAGE_SIZE##&pageIndex=##PAGE_INDEX##'
};

// Gaiapod List
export const getGaiapodList = async () => {
    const { getState } = store;
    const { page, rowsPerPage } = getState().gaiapod;
    const res = await securedAxiosAPI.get(
        securedRoute?.list.replaceAll('##PAGE_SIZE##', rowsPerPage).replaceAll('##PAGE_INDEX##', page + 1)
    );
    return res.data;
};

// Add New Gaiapod
export const addNewGaiapodToList = async (data) => {
    const res = await securedAxiosAPI.post(securedRoute?.addNewGaiapod, data);
    return res.data;
};
// Update Gaiapod By Id
export const updateGaiapodById = async (data) => {
    const { id } = data;
    const res = await securedAxiosAPI.post(securedRoute?.updateGaiapod.replaceAll('##ID##', id), data);
    return res.data;
};
// Get Gaiapod By Id
export const getGaiapodById = async (id) => {
    const res = await securedAxiosAPI.get(securedRoute?.getGaiapodDetailsById.replaceAll('##ID##', id));
    return res.data;
};
//   Gaiapod History By Id
export const getGaiapodHistoryById = async ({ id, page, rowsPerPage }) => {
    const res = await securedAxiosAPI.get(
        securedRoute?.getGaiapodHistory
            .replaceAll('##ID##', id)
            .replaceAll('##PAGE_SIZE##', rowsPerPage)
            .replaceAll('##PAGE_INDEX##', page + 1)
    );
    return res.data;
};
