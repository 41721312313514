/**
 * axios setup to use mock service
 */

import axios from 'axios';
import { dispatch, store } from '../store';
import { loggedInUser, logOutUser, startLogout } from 'store/slices/user';
import { openSnackbar } from 'store/slices/snackbar';

const axiosServices = axios.create({ baseURL: process.env.REACT_APP_API_URL || 'http://localhost:3010/' });

// interceptor for http
axiosServices.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject((error.response && error.message) || 'Wrong Services')
);

const securedAxios = () =>
    axios.create({
        // baseURL: 'http://localhost:5000/',
        // baseURL: 'http://192.168.0.105:5001/',
        baseURL: process.env.REACT_APP_LIMS_API,
        withCredentials: true
    });

const myBasicAxios = () =>
    axios.create({
        // baseURL: 'http://localhost:4000/',
        baseURL: process.env.REACT_APP_LIMS_IAM,
        withCredentials: true
    });

const myPrivateAxios = () => {
    axios.create({
        baseURL: 'http://localhost:5000',
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
    });
};
export const securedAxiosAPI = securedAxios();
export const basicAxios = myBasicAxios();
export const privateAxios = myPrivateAxios();

// Request Handler

securedAxiosAPI.interceptors.request.use(
    (config) => {
        const { getState } = store;
        const { userToken } = getState().user.loggedInUser;
        // console.log(config.headers);
        if (!config.headers.Authorization) {
            config.headers.Authorization = `Bearer ${userToken}`;

            //  config.headers['Content-Type'] = 'multipart/form-data';
        }
        return config;
    },
    (error) => Promise.reject(error)
);

let isRefreshing = false; // Indicates if a refresh is already in progress
let refreshSubscribers = []; // Queue to hold pending requests

// Function to add subscribers
const subscribeTokenRefresh = (callback) => {
    refreshSubscribers.push(callback);
};

// Function to notify subscribers
const onRrefreshed = (newToken) => {
    refreshSubscribers.forEach((callback) => callback(newToken));
    refreshSubscribers = []; // Clear the queue
};

// Response Handler

securedAxiosAPI.interceptors.response.use(
    (response) => response,
    async (error) => {
        const prevRequest = error?.config;
        if (error?.response?.status === 403 && !prevRequest?.sent) {
            // console.log(prevRequest, 'prevRequest One ');

            if (!isRefreshing) {
                isRefreshing = true;

                try {
                    const resp = await basicAxios.get('master_auth/refresh', {
                        withCredentials: true
                    });

                    const {
                        accessToken,
                        user: { id, email, name, role, role_name: roleName, customer_id: customerId, customer_name: companyName },
                        userRoles
                    } = resp?.data;

                    // Update token in store
                    dispatch({
                        type: loggedInUser.type,
                        payload: {
                            userID: id,
                            email,
                            name,
                            userToken: accessToken,
                            roleId: role,
                            roleName,
                            userRoles,
                            customerId,
                            companyName
                        }
                    });

                    // Notify all subscribers
                    onRrefreshed(accessToken);

                    if (isRefreshing) {
                        isRefreshing = false;
                        // calling the first request for which the token was expired
                        // As it will not fall into the last code whiich subscribes the requests while new token not fetched. subscribeTokenRefresh
                        prevRequest.headers.Authorization = `Bearer ${accessToken}`;
                        return basicAxios(prevRequest);
                    }
                } catch (refreshError) {
                    isRefreshing = false;
                    // alert('Hello');
                    dispatch({ type: logOutUser.type });
                    dispatch({ type: startLogout.type });
                    dispatch({
                        type: openSnackbar.type,
                        payload: {
                            open: true,
                            message: 'Session Expired',
                            variant: 'alert',
                            alert: { color: 'error' },
                            close: false,
                            actionButton: false
                        }
                    });
                    window.location.href = '/login';
                    Promise.reject(refreshError);
                }
            }

            // Queue the request and retry after refresh
            // console.log(prevRequest, 'All Requests Added');
            return new Promise((resolve) => {
                subscribeTokenRefresh((newToken) => {
                    prevRequest.sent = true;
                    prevRequest.headers.Authorization = `Bearer ${newToken}`;
                    resolve(basicAxios(prevRequest));
                });
            });
        }

        // If the error is not 403, handle it normally
        const myError = error?.response?.data?.message || 'An unknown error occurred.';
        throw new Error(myError);
    }
);

export default axiosServices;
