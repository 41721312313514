import { securedAxiosAPI, basicAxios } from 'utils/axios';
import { store } from '../../store';
// APIS
const config = {
    headers: {
        'Content-Type': 'multipart/form-data'
    }
};
// Secured Routes
/// /userMaster/userByPagination
const securedRoute = {
    addUser: 'userMaster/',
    addUserDetails: 'userDetails/',
    allUser: 'userMaster/',
    userByPagination: 'userMaster/userByPagination',
    dynamicFilter: 'userMaster/dynamicFilter',
    base: 'userMaster',
    updateUserData: '/userMaster/update-details',
    resetCurentPassword: '/userMaster/update-profile-pswd',
    resetUserPasswordByAdmin: '/userMaster/reset-user-password-by-admin',
    userByRoleId: '/userMaster/user-by-role/##ROLEID##',

    // customer users list by customer id
    customerUsersList: '/userMaster/user-list/##CUSTOMER_ID##',

    // not getting used. Code only receive while unsecured connection (on forgot of password)
    updatePasswordByCode: '/userMaster/update-pswd-code',
    notificationsCount: '/notification/count?user_id=##USERID##',
    notificationsList: '/notification?user_id=##USERID##&limit=##LIMIT##&page_no=##PAGENO##'
};

// Un-secured Routes
const unsecuredRoute = {
    login: 'master_auth',
    refresh: 'master_auth/refresh',
    logout: 'master_auth/logout',
    customerUserLogout: 'customer-user-auth/logout', // no need
    forgodPasswordSendCode: 'master_auth/forgot-password',
    restPassword: 'master_auth/reset-password-by-code',
    customerRefresh: 'customer-user-auth/refresh' // no need
};

// Axios call

export const loginUser = async (data) => {
    const res = await basicAxios.post(unsecuredRoute?.login, data);
    return res.data;
};
export const refresh = async () => {
    const res = await basicAxios.get(unsecuredRoute?.refresh);
    return res.data;
};
export const customerRefresh = async () => {
    const res = await basicAxios.get(unsecuredRoute?.customerRefresh);
    return res.data;
};
export const logout = async () => {
    //
    const { getState } = store;

    const { loggedInPortal } = getState().user;
    // alert(loggedInPortal);
    const res = await basicAxios.get(unsecuredRoute?.logout);
    // if (loggedInPortal === 'LAB') {
    //     res = await basicAxios.get(unsecuredRoute?.logout);
    // } else if (loggedInPortal === 'CUSTOMER') {
    //     //
    //     res = await basicAxios.get(unsecuredRoute?.customerUserLogout);
    // }

    return res?.data;
};
// password
export const forgodPasswordSendCode = async (data) => {
    const res = await basicAxios.post(unsecuredRoute?.forgodPasswordSendCode, data);
    return res.data;
};
export const restPassword = async (data) => {
    const res = await basicAxios.post(unsecuredRoute?.restPassword, data);
    return res.data;
};
// export const sendTheVerificationMailOnAdmin = async (data) => {
//     const res = await securedAxiosAPI.post(unsecuredRoute?.mailing, data);
//     return res.data;
// };
// secured
export const addNewUser = async (data) => {
    const { userData, addUserDetailData, signature } = data;
    const formData = new FormData();
    formData.append('userData', JSON.stringify(userData));
    formData.append('addUserDetailData', JSON.stringify(addUserDetailData));
    formData.append('signature', signature);
    const res = await securedAxiosAPI.post(securedRoute?.addUser, formData, config);
    return res.data;
};

export const userByPagination = async (customerId) => {
    const { getState } = store;
    const { page, rowsPerPage } = getState().user;
    const res = await securedAxiosAPI.post(securedRoute?.userByPagination, {
        page,
        rowsPerPage,
        customer_id: customerId
    });
    return res.data;
};
export const addUserDetail = async (data) => {
    const res = await securedAxiosAPI.post(securedRoute?.addUserDetails, data);
    return res.data;
};
// Dynamic Filter
export const dynamicFilter = async (data) => {
    const res = await securedAxiosAPI.post(securedRoute?.dynamicFilter, data);
    return res.data;
};
export const usersDetailsById = async (id) => {
    const res = await securedAxiosAPI.get(`${securedRoute?.base}/${id}`);
    // const { getState } = store;

    // const { loggedInPortal } = getState().user;
    // let res;
    // if (loggedInPortal === 'LAB') {
    //     res = await securedAxiosAPI.get(`${securedRoute?.base}/${id}`);
    // } else if (loggedInPortal === 'CUSTOMER') {
    //     //
    //     res = await securedAxiosAPI.get(`${securedRoute?.customerUser}/${id}`);
    // }

    return res.data[0];
};
// UpdateUserData
export const updateUserData = async (data) => {
    const { signature, ...userData } = data;

    const formData = new FormData();
    formData.append('userData', JSON.stringify(userData));
    formData.append('signature', signature);
    //

    const res = await securedAxiosAPI.post(securedRoute?.updateUserData, formData, config);
    return res.data[0];
};
// Delete
export const deleteUsersById = async (id) => {
    const res = await securedAxiosAPI.delete(`${securedRoute?.base}/${id}`);
    return res.data[0];
};
// Reset by user paswd by using current pswd
export const resetCurentPassword = async (data) => {
    const res = await securedAxiosAPI.post(securedRoute?.resetCurentPassword, data);
    return res;
};

export const resetUserPasswordByAdmin = async (data) => {
    const res = await securedAxiosAPI.post(securedRoute?.resetUserPasswordByAdmin, data);
    return res.data;
};

// // Sent the Mail Update Password by code // not getting used. Code only receive while unsecured connection
export const updatePasswordByCode = async (data) => {
    const res = await securedAxiosAPI.post(securedRoute?.updatePasswordByCode, data);
    return res.data;
};

export const usersListByRoleId = async (roleId) => {
    const res = await securedAxiosAPI.get(securedRoute.userByRoleId.replaceAll('##ROLEID##', roleId));
    return res.data;
};

// notificationsCount

export const fecthNotificationsCountByUserId = async () => {
    const { getState } = store;
    const { loggedInUser } = getState().user;
    const res = await securedAxiosAPI.get(securedRoute.notificationsCount.replaceAll('##USERID##', loggedInUser?.userID));
    return res.data[0];
};
// notificationsList

export const fecthNotificationsList = async ({ limit = 100, pageNo = 1, readStatus = 0 }) => {
    const { getState } = store;
    const { loggedInUser } = getState().user;
    const res = await securedAxiosAPI.get(
        securedRoute.notificationsList
            .replaceAll('##USERID##', loggedInUser?.userID)
            .replaceAll('##LIMIT##', limit)
            .replaceAll('##PAGENO##', pageNo)
            .replaceAll('##READ##', readStatus)
    );
    return res.data;
};

// customerUsersList
export const fecthCustomerUsersList = async (customerId) => {
    const res = await securedAxiosAPI.get(securedRoute.customerUsersList.replaceAll('##CUSTOMER_ID##', customerId));
    return res.data;
};
