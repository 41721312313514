const LAYOUT_CONST = {
    VERTICAL_LAYOUT: 'vertical',
    HORIZONTAL_LAYOUT: 'horizontal',
    DEFAULT_DRAWER: 'default',
    MINI_DRAWER: 'mini-drawer'
};
export const labProcedureUsed = {
    4: 'PRO-LAB-01 Determination of E. coli by Optical Verification, PRO-LAB-02 Determination of Enterobacteriaceae by Optical Verification, PRO-LAB-03 Determination of TVC by Optical Verification, PRO-LAB-04 Determination of Mold and Yeast by Optical Verification',
    5: 'PRO-LAB-09 Determination of Allergen level by S-ELISA',
    6: 'PRO-LAB-06 Determination of NOPS by LCMS',
    14: 'PRO-LAB-05 Determination of Available Water Content',
    15: 'PRO-LAB-07 Determination of Mycotoxin Levels by LCMS',
    16: 'PRO-LAB-08 Determination of Moisture Content by Loss of Drying (LOD)',
    17: 'PRO-LAB-10 Determination of Sample pH'
};
export const calibrationHead = {
    14: 'Relative Humidity (ERH) of Standard',
    17: 'pH of Standard Buffer'
};
export const analysisTypes = {
    4: 'MICROBIOLOGY_TYPE',
    5: 'ALLERGENS_TYPE',
    6: 'NOPS_TYPE',
    14: 'WATER_ACTIVITY',
    15: 'MYCOTOXIN_TYPE',
    16: 'MOISTURE_TYPE',
    17: 'PH_TYPE'
};
export const analysisNameTypes = {
    4: 'MICROBIOLOGY',
    5: 'ALLERGENS',
    6: 'NOPS',
    14: 'WATER ACTIVITY',
    15: 'MYCOTOXIN ',
    16: 'MOISTURE ',
    17: 'pH'
};
export const resultPrefix = {
    1: '<',
    2: '>'
};
export const formsQMSType = {
    4: 'E ',
    5: 'F ',
    6: 'A ',
    14: 'C ',
    15: 'B ',
    16: 'D ',
    17: 'H '
};
export const analysisResults = {
    4: [
        'This analysis was performed using a Neogen Soleris machine.',
        'These results relate only to the sample(s) tested and do not guarantee the bulk of the material to be of equal quality.'
    ],
    5: [
        'The sample(s) were tested by S-ELISA and a Neogen STAT FAX 4700 Allergen Reader machine.',
        'These results relate only to the sample(s) tested and do not guarantee the bulk of the material to be of equal quality.'
    ],
    6: [
        'This analysis was performed using LC-MS/MS (Agilent 6470 Triple Quadrupole System).',
        'These results relate only to the sample(s) tested and do not guarantee the bulk of the material to be of equal quality.'
    ],
    14: [
        'This analysis was performed using an AW LAB Set H water activity machine to measure the equilibrium relative humidity (ERH%). Water activity (aw) is equal to ERH%/100.',
        'These results relate only to the sample(s) tested and do not guarantee the bulk of the material to be of equal quality.'
    ],
    15: [
        'This analysis was performed using LC-MS/MS (Agilent 6470 Triple Quadrupole System).',
        'These results relate only to the sample(s) tested and do not guarantee the bulk of the material to be of equal quality.'
    ],
    16: [
        'This analysis was performed using a Ohaus MB23 Moisture Analysis machine, and represents the sample received.',
        'These results relate only to the sample(s) tested and do not guarantee the bulk of the material to be of equal quality.'
    ],
    17: [
        'This analysis was performed using a Mettler Toledo Easy 5 F20 pH meter.',
        'These results relate only to the sample(s) tested and do not guarantee the bulk of the material to be of equal quality.'
    ]
};

export const analysisList = [
    { name: 'MICROBIOLOGY', id: 4 },
    { name: 'ALLERGENS', id: 5 },
    {
        name: 'NOPS',
        id: 6
    },
    {
        name: 'WATER ACTIVITY',
        id: 14
    },
    {
        name: 'MYCOTOXIN',
        id: 15
    },
    {
        name: 'MOISTURE',
        id: 16
    },
    {
        name: 'pH',
        id: 17
    }
];
export const wheelockRegex = new RegExp(`^(?=.*Wheelock).*Wheelock.*$`, 'i');
// name regex gen
export const namesRegex = (password, regexArray) => {
    let bool;
    regexArray.forEach((item, index) => {
        if (index === 0) {
            // console.log(item.test(password), item);
            bool = item.test(password);
        } else {
            bool = bool || item.test(password);
        }
    });
    return bool;
};
export const labName = 'Wheelock Scientific Services';
export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{12,18}$/;
export const myAnalysisArray = {
    microbiology: 4,
    allergens: 5,
    nops: 6,
    waterActivity: 14,
    mycotoxin: 15,
    moistureAnalysis: 16,
    pH: 17
};

export const testResultType = [
    { name: 'Boolean Type', value: 11 },
    { name: 'Select Type', value: 12 },
    { name: 'Text Type', value: 13 }
];

export const pickupRequestStatus = {
    0: 'Pickup Requested',
    1: 'Pickup Accepted',
    2: 'Pickup Scheduled / Courier Assigned',
    3: 'In-Transit / Pickup Done',
    4: 'Delivered / Arrived at Lab '
};

export const gaiapodsStatus = {
    0: 'Ready to Allocate(Empty)',
    1: 'Allocated ',
    2: 'Filled',
    3: 'Empty but Decontaminated',
    4: 'Send Decontamination ',
    5: 'Decontamiated and Ready for use',
    6: 'Lost',
    7: 'Inactive'
};

export default LAYOUT_CONST;
